/*   Section_1   */
.section_1{
    position: relative;
    width: auto;
    background-size: auto;
    background-position: center;

    &_img{
        width: 100%;
    }

    &_content{
        position: absolute;
        top: 25rem;
        left: 31rem;

        @media (max-width: 1595px){
            top: 22rem;
            left: 25rem;
        }
        @media (max-width: 1500px){
            top: 20rem;
            left: 23rem;
        }
        @media (max-width: 1345px){
            top: 18rem;
            left: 20rem;
        }
        @media (max-width: 1200px){
            top: 20rem;
            left: 20rem;
        }
        @media (max-width: 1030px){
            top: 20rem;
            left: 18rem;
        }
        @media (max-width: 900px){
            top: 16rem;
            left: 15rem;
        }
        @media (max-width: 800px){
            top: 16rem;
            left: 12rem;
        }

        @media (max-width: 800px){
           display: none;
        }
    }
    &_title{
        color: $color-white;
        font-size: 6rem;
        font-weight: 600;

        @media (max-width: 1345px){
             font-size: 5.5rem;
        }
        @media (max-width: 1200px){
             font-size: 4.5rem;
        }
    }
    &_secTitle{
        color: $color-white;
        text-align: center;
        font-size: 2.5rem;
        padding-right: 9rem;

        @media (max-width: 1345px){
            font-size: 2.3rem;
            padding-right: 7rem;
        }
        @media (max-width: 1200px){
             font-size: 2rem;
             padding-right: 6rem;
        }
        span{
            color: $color-primary4;
            font-size: 3rem;
            font-weight: 600;

            @media (max-width: 1345px){
                font-size: 2.8rem;
            }
            @media (max-width: 1200px){
             font-size: 2.5rem;
        }
        }
    }
    &_thirdTitle{
        color: $color-white;
        text-align: center;
        font-size: 2.5rem;

        @media (max-width: 1345px){
            font-size: 2.3rem;
        }
        @media (max-width: 1200px){
            font-size: 2rem;
        }
        span{
            color: $color-primary4;
            font-weight: 600;
        }
    }

    /*   外部引用component   */

    Button{
        width: 23rem;
        height: 5rem;
        font-size: 2rem;
        margin-top: 9rem;
        color: $color-white;
        background-color: $color-primary4;
        text-align: center;
        border: none;
        transition: all .2s;

        @media (max-width: 1345px){
            width: 20rem;
            margin-top: 6rem;
        }
        @media (max-width: 900px){
            width: 16rem;
             margin-top: 4rem;
             font-size: 1.6rem;
        }

        &:hover{
            cursor: pointer;
            transform: translateY(-3px);
            box-shadow: 0 1rem 2rem rgba($color-primary4, .4);
            scale: 1.02;
            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
        &:active{
            outline: none;
            transform: translateY(-1px);
            box-shadow: 0 .5rem 1rem rgba($color-primary4,.2);
        }

    }

}
/*   Section_2  */
.section_2{
    height: 39.5rem;
   @include component_Layout;

   @media (max-width: 700px){
    height: 70rem;
   }
   @media (max-width: 600px){
    height: 65rem;
   }
   @media (max-width: 425px){
    height: 55rem;
   }
   @media (max-width: 375px){
    height: 52rem;
   }
}

/*   Section_3  */
.section_3{
    position: relative;
    @include component_Layout;
    align-items: center;
    height: 80rem;

    @media (max-width: 1200px){
        height: 70rem;
    }

    @media (max-width: 607px){
        height: 80rem;
    }

    @media (max-width: 415px){
        height: 70rem;
    }


    
    &_overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../image//Home-img/BG_2.jpg');
        background-size: cover;
        background-position: center;
        opacity: .4;
        z-index: -111;
       
    }
}

/*   Section_4  */
.section_4{
    @include component_Layout;
    height: 51rem;
    margin-top: 8rem;

    @media (max-width: 1205px){
        margin-bottom: 0;
    }
}

/*   Section_5  */
.section_5{
    @include component_Layout;
    height: 72rem;
    margin-top: 8rem;

    @media (max-width: 1519px){
        height: auto;
        margin: 0;
    }
    @media (max-width: 537px){
        margin-top: 8rem;
    }
    @media (max-width: 415px){
        margin-top: 5rem;
    }
    @media (max-width: 375px){
        margin-top: 2.5rem;
    }
    @media (max-width: 320px){
        margin-top: 1rem;
    }
}

