.business{
    width: 68%;

    @media (max-width: 900px){
        width: 85%;
    }
   
    &_title{
        @include component_title;
    }

    &_title_div{
        @include component_title_div;
    }

    &_title_text{
        @include component_title_text;
    }

    &_cards{
        width: 100%;
        height: auto;
        margin-top: 2rem ;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    &_card{
        width: 25rem;
        height: 16rem;
        margin: 2rem ;
        border-radius: 20px;
        background-color: $color-primary2;
        box-shadow: rgba(0, 0, 0, 0.25) 8px 8px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 1250px){
            width: 20rem;
            height: 14rem;
            margin: 1rem ;
        }

        @media (max-width: 655px){
            height: 12rem;
        }
        @media (max-width: 415px){
            width: 16rem;
            height: 11rem;
            margin: .5rem ;
        }

        @media (max-width: 320px){
            width: 14rem;
        }

        p{
            color: $color-white;
            font-size: 2.5rem;
            width: auto;
            height: auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1.3;
            padding: 0 1rem;

            @media (max-width: 1500px){
                font-size: 2.3rem;
            }
            @media (max-width: 1250px){
                font-size: 2rem;
            }
            @media (max-width: 480px){
               font-size: 1.8rem;
            }
            @media (max-width: 320px){
                font-size: 1.6rem;
            }
        }
    }

    &_btn{
        @include blue_btn_layout;
        
        Button{
            @include blue_btn_contant;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: 2px solid $color-primary2;
        }
    }
}