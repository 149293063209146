/*   Color   */
$color-primary1: #00001D;
$color-primary2: #0A3468;
$color-primary3: #0C4182;
$color-primary4: #FFB82B;
$color-polygon:#d9d9d9;
$color-gray:#E2E2E2;
$color-gray-1:#D9D9D9;
$color-gray-text:#727171;
$color-black: #000;
$color-white: #fff;

/*   Font   */
$default-font-size: 1.6rem;
