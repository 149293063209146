.achievement{
    width: 100%;
    position: relative;

    &_title{
        font-size: 3.5rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        padding: 5rem 0;

        @media (max-width: 1519px){
            padding: 3rem 0;
        }
        @media (max-width: 450px){
            font-size: 3rem;
        }
        @media (max-width: 375px){
            font-size: 2.8rem;
        }
        @media (max-width: 340px){
            font-size: 2.5rem;
        }
        @media (max-width: 320px){
            font-size: 2.2rem;
        }
     

    }

    &_background{
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, $color-primary4 0%,
                     rgba($color-primary4, 0.7) 100%);
        height: 35.1rem;
        width: 100%;
        z-index: -1;

        @media (max-width: 1519px){
            padding: 3rem 0;
        }
    }

    &_cards{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 2rem;
    }

    &_btn{
        @include blue_btn_layout;
        
        Button{
           @include blue_btn_contant;
            border: none;
            color: $color-white;
        }

        @media (max-width: 1519px){
           margin-bottom: 5rem;
        }
    }
}