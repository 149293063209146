.why {
    width: 68%;
    height: 39.5rem;

    @media (max-width: 900px) {
        width: 85%;
    }

    &_title {
        @include component_title;
    }

    &_title_div {
        @include component_title_div;
    }

    &_title_text {
        @include component_title_text;
    }

    &_container {
        width: 100%;
        height: 80%;
        margin-top: 4rem;
        display: flex;
        flex-direction: row;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }

    &_container_2 {
        width: 100%;
        height: 80%;
        margin-top: 4rem;
        display: flex;
        flex-direction: row-reverse;
        // flex-direction: row;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }


    &_container_left {
        width: 100%;
    }

    &_left_title {
        font-size: 2.5rem;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        line-height: 1.2;

        @media (max-width: 700px) {
            padding: 0;
        }
    }

    &_left_text {
        color: $color-gray-text;
        font-size: $default-font-size;
        padding: 2rem;
        line-height: 1.2;

        @media (max-width: 700px) {
            padding: 2rem 0;
        }
    }

    &_container_right {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 700px) {
            justify-content: center;
            width: 100%;
            height: auto;
        }

        iframe {
            border: 1px solid $color-black;
            box-shadow: rgba($color-black, 0.2) 0px 2px 8px 0px;

            @media (max-width: 900px) {
                width: 100%;
                height: 100%;
            }

            @media (max-width: 700px) {
                height: 40rem;
            }

            @media (max-width: 600px) {
                height: 36rem;
            }

            @media (max-width: 425px) {
                height: 25rem;
            }

            @media (max-width: 375px) {
                height: 23rem;
            }

            @media (max-width: 375px) {
                height: 20rem;
            }
        }
    }

    &_container_2_right {
        width: 100%;
        display: flex;
        // justify-content: flex-end;

        @media (max-width: 700px) {
            justify-content: center;
            width: 100%;
            height: auto;
        }

        iframe {
            border: 1px solid $color-black;
            box-shadow: rgba($color-black, 0.2) 0px 2px 8px 0px;

            @media (max-width: 900px) {
                width: 100%;
                height: 100%;
            }

            @media (max-width: 700px) {
                height: 40rem;
            }

            @media (max-width: 600px) {
                height: 36rem;
            }

            @media (max-width: 425px) {
                height: 25rem;
            }

            @media (max-width: 375px) {
                height: 23rem;
            }

            @media (max-width: 375px) {
                height: 20rem;
            }
        }
    }
}