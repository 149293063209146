.achievement_card{
    width: 35rem;
    height: 43rem;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, 
                rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                
                @media (max-width: 607px){
                    width: 95%;
                }

    &_img{
        width: 100%;
        height: 30rem;
        background-color: $color-gray;
        opacity: .8;
       
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px 5px 0 0;
        }
    }

    &_content{
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        border-radius: 0 0 5px 5px ;
    }

    &_title{
        p{
            font-size: 2rem;
            font-weight: 600;
            overflow:hidden;
            text-overflow: ellipsis; 
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    &_text{
        padding-bottom: 1rem;
        font-size: 1.6rem;
        height: 100%;

        p{
            height: 7rem;
            overflow:hidden;
            text-overflow: ellipsis; 
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: $color-gray-text;
        }
      
    }
}