.footer{
    width: 100%;
    height: auto;
    background-color: $color-primary2;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_container{
        width: 68%;
        height: auto;
        margin: 4rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 1500px){
             width: 75%;
        }
        @media (max-width: 1345px){
            width: 80%;
            justify-content: space-around;
        }
        @media (max-width: 1150px){
            width: 85%;
        }
        @media (max-width: 800px){
            width: 90%;
            flex-direction: column;
        }
    }

    &_left{
        width: 50%;
        height: 100%;

        @media (max-width: 1520px){
            width: 45%;
        }
        @media (max-width: 800px){
            width: 100%;
            flex-direction: column;
        }
    }
    

    &_left_title{
        font-size: 2rem;
        color: $color-white;
        height: 5rem;
        width: 100%;
        border-bottom: 1px solid $color-white;
    }

    &_left_contant{
        font-size: 1.6rem;
        color: $color-white;
        width: 90%;
        height: 11.5rem;
        display: flex;
        align-items: center;

        @media (max-width: 800px){
            height: auto;
            margin: 1rem 0;
        }
    }

    &_right{
        width: 50%;
        display: flex;
        flex-direction: row;

        @media (max-width: 800px){
            width: 100%;
            justify-content: space-around;
            align-items: center;
        }
    }

    &_list{
        width: 70%;
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 0 1rem;

        @media (max-width: 1200px){
            width: 60%;
        }
        @media (max-width: 1030px){
            width: 40%;
        }
        @media (max-width: 800px){
            width: 100%;
        }
        @media (max-width: 530px){
            width: 80%;
        }
        @media (max-width: 480px){
            width: 70%;
        }
        @media (max-width: 450px){
            width: 50%;
        }
        @media (max-width: 450px){
            width: 35%;
        }
  
        li{
            font-size: 2rem;
            color: $color-white;
            height: 3rem;
            margin: 1rem 0;
            text-align: center;
            
            @media (max-width: 1200px){
                font-size: 1.8rem;
            }

            a{
                text-decoration: none;
                color: $color-white;
                transition: all .2s ease-in-out;

                @media (max-width: 800px){
                   color: $color-gray-1;
                }

                &:hover{
                    color: $color-primary4;
                    border-bottom: 1px solid $color-primary4;
                }
            }
        }
    }

    &_contact{
        width: 100%;
        height: 22rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 800px){
            justify-content: space-around;
        }
    }

    &_contact_time,
    &_contact_phone,
    &_contact_address{
        width: 100%;
        height: 6rem;
        display: flex;
        align-items: center;

        @media (max-width: 800px){
            
        }
    }
    
    &_contact_circle{
        width: 6rem;
        height: 6rem;
        background-color: $color-primary3;
        border-radius: 50%;
        position: relative;

        @media (max-width: 1595px){
            width: 5rem;
            height: 5rem;
        }

        @media (max-width: 1545px){
            width: 4rem;
            height: 4rem;
        }
    }
    
    &_contact_icon{
        @include absolute-center;
        color: $color-primary4;
        width: 3.5rem !important;
        height: 3.5rem !important;

        @media (max-width: 1595px){
            width: 3rem !important;
            height: 3rem !important;
        }
        @media (max-width: 1545px){
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
        @media (max-width: 1500px){
            width: 2rem !important;
            height: 2rem !important;
        }
    }

    &_contact_text{
        font-size: 1.5rem;
        color: $color-white;
        margin-left: 1.5rem;
    }

    &_copyright{
        width: 100%;
        height: auto;
        border-top: 1px solid $color-white;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
            margin: 1.5rem 0;
            font-size: 1.5rem;
            color: $color-white;
        }
    }
}