@mixin component_Layout{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem 0;

    @media (max-width: 600px) {
        margin-top: 3rem;
        margin-bottom: 0;
    }
}

@mixin component_title{
    width: 12rem;
    height:2.53rem;
    background-color: $color-gray;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin component_title_div{
    width: 1rem;
    height: 2.5rem;
    margin-right: 1rem;
    background-color: $color-primary4;
}

@mixin component_title_text{
    margin-left: 1rem;
    font-size: $default-font-size;
}

@mixin polygon_side{
    width: 1.54rem;
    height: 4.4rem;
    background-color: $color-polygon;
    opacity: .6;
    clip-path: polygon(0 0 , 100% 0, 100% 0, 0 100%);
}

/*   Blue_Button   */

@mixin blue_btn_layout{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

@mixin blue_btn_contant{
        width: 15.3rem;
        height: 5.3rem;
        background: linear-gradient(180deg, $color-primary2 0%,
                         rgba(10, 52, 104, 0.7) 100%);
        font-weight: 600;
        filter: drop-shadow(4px 4px 5px rgba($color-black, .2));
        border-radius: 20px;
        transition: all .2s;

     &:hover{
            cursor: pointer;
            transform: translateY(-2px);
            box-shadow: 0 1rem 2rem rgba($color-primary2, .2);

               &::after {
                    transform: scaleX(1.1) scaleY(1.1);
                    opacity: 0;
                }
     }
     
     &:active{
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba($color-primary2,.2);
    }
}

/*   Image_Tittle   */

@mixin image_title_1{
    font-size: 6rem;
    font-weight: 600;
}

@mixin image_title_2{
    font-size: 3rem;
    font-weight: 600;
}

/*   CompanyInfo*/

@mixin company_contact{
    width: 100%;
    height: 40rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
        height: 30rem;
    }

    @media (max-width: 600px) {
        height: 25rem;
        margin: 2rem 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, 
                    rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    @media (max-width: 530px) {
        height: 28rem;
    }

    &-img{
        width: 50%;

        @media (max-width: 600px) {
            width: 40%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-text{
        width: 50%;
        padding: 0 4rem;
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
            width: 60%;
            padding: 0 2rem;
        }

        p{
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.3;
            text-indent: 4rem;

            @media (max-width: 800px) {
                font-size: 1.9rem;
                font-weight: normal;
            }

            @media (max-width: 700px) {
                font-size: 1.6rem;
                text-indent: 3rem;
            }
           
        }
    }
}

/*   Position   */

@mixin absolute-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Media Query 
/*
    0 - 600px:         iphone
    600 - 900px:       Table portrait
    900 - 1200px:      Table landspace
    [1200 - 1800]      normal style
    1800px +           Big desktop

    $breakpoint 選擇  1em = 16px
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content };   //600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content };   //900px
    }
    @if $breakpoint == tab-land{
        @media (max-width: 75em) { @content };   //1200px
    }
    // @if $breakpoint == big-desktop{
    //     @media (max-width: 112.5em) { @content };   //1800px
    // }
}