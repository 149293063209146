*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    font-size: 62.5%;
    font-family: 'Poppins', sans-serif;

    @include respond(phone){
        font-size: 30%; //1rem = 4.8px
    }

    @include respond(tab-land){
        font-size: 56.25%; //1rem = 9px
    }

    @include respond(tab-port){
        font-size: 50%; //1rem = 8px
    }

    // @include respond(big-desktop){
    //     font-size: 75%; //1rem = 12px
    // }
}

body{
    box-sizing: border-box;
    // position: relative;

}