.page_info{
    background-color: $color-primary2;
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 450px) {
        height: 3.5rem;
    }

    p{
        background-color: $color-primary4;
        height: 100%;
        width: 18.5rem;
        font-size: 2.5rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 450px) {
            font-size: 1.6rem;
        }
    }
}