.nav{
    display: flex;
    flex-wrap: wrap;
    padding: 2.5rem 12rem;
    justify-content: space-around;
    background-color: $color-primary2;
    box-shadow: rgba($color-black, 0.16) 0px 3px 6px, 
                rgba($color-black, 0.23) 0px 3px 6px;
    position: sticky;
    top: 0;
    z-index: 999;
    position: fixed;
    width: 100%;

    @include respond (tab-port){
        padding: 2.5rem 8rem;
        justify-content: space-between;
    }

    @include respond (phone){
        padding: 2.5rem 5rem ;
    }

    @media (max-width: 450px){
        padding: 2rem 2.5rem ;
    }
    
    &_icon{
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-white;
        img{
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
        }
        a{
            text-decoration: none;
            p{
                font-size: 3rem;
                font-weight: 400;
                color: $color-white;
                &:hover{
                    cursor: pointer;
                }
            }
            &:active{
                text-decoration: none;
            }
        }

        @include respond (phone){
            img{
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
            }
            a{
                p{
                    font-size: 2rem;
                }
            }
        }

      
    }

    &_routers{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        list-style: none;
        li{
            margin: 0 1rem;
        }

        @media (max-width: 1205px) {
            margin-top: 1rem;
            width: 100%;
        }

        @include respond (tab-port){
            display: none;
        }
    }

    &_link{
        padding: 1rem 2rem;
        font-size: 1.8rem;
        font-weight: 400;
        color: $color-white;
        text-decoration: none;
        transition: all .2s ease-in-out;

        &:hover, 
        &:focus{ 
            border-bottom: 2px solid $color-primary4;
            font-weight: 600;
            color: $color-primary4;
        }
    }

    &_toggle_btn{
        color: $color-white;
        display: none;
        align-items: center;
        font-size: 2.5rem;
        font-weight: 300;

        &:hover {
            cursor: pointer;
        }

        @include respond (tab-port){
            display: flex;
        }

        @include respond (phone){
            font-size: 2rem;
        }
    
    }

    &_toggle_icon{
        width: 3.5rem !important;
        height: 3.5rem !important;
        padding: .5rem;
        border-radius: 5px;

        @include respond (phone){
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
    }

    &_dropdown_menu{
        display: none;
    }

    &_dropdown_menu_open{
        position: absolute;
        background-color: rgba($color-primary3, .95);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        top: 0;
        right: 0;
        width: 40%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        list-style: none;

        li{
            margin: 1rem 1rem;
            text-align: center;

            a{
               font-size: 2rem;
            }
        }
    }

    &_dropdown_menu_icon{
        margin: 2rem 0 2rem 2.5rem;
        width: 2rem !important;
        height: 2rem !important;

    
    }
}
