.ach{
    width: 41.5rem;
    height: auto;
    margin: 2rem 0;
    box-shadow: 8px 8px 5px 0px rgba($color-black, 0.25);
   
    &_img{
        width: 100%;
        // height: 32rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        img{
            width: 100%;
            // height: 32rem;
            height: 100%;
            object-fit: contain;
        }
    }

    &_title{
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7rem;
        color: $color-white;
        background-color: $color-black;
        padding: 5rem 1rem;
        text-align: center;
    }
}