.image_part2{
    height: 54rem;
    width: 100%;
    position: relative;

    @media (max-width: 800px) {
        height: 40rem;
    }

    @media (max-width: 600px) {
        height: 35rem;
    }

    @media (max-width: 530px) {
        height: 30rem;
    }

    @media (max-width: 450px) {
        height: 25rem;
    }

    img{
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover; 
    }

    &_titles{
        @include absolute-center ;
        top:55% !important;
        display: flex;
        flex-direction: column;
        text-align: center;

        @media (max-width: 800px) {
            top:60% !important;
        }
    }

    &_en_title{
        display: flex;
        flex-direction: row;

        p{
            @include image_title_1;
            color: $color-primary4;

            @media (max-width: 800px) {
                font-size: 4.5rem;
            }
            @media (max-width: 530px) {
                font-size: 4rem;
            }
            @media (max-width: 450px) {
                font-size: 3.5rem;
            }
        }

        span{
            @include image_title_1;
            margin-left: 1rem;

            @media (max-width: 800px) {
                font-size: 4.5rem;
            }

            @media (max-width: 530px) {
                font-size: 4rem;
            }

            @media (max-width: 450px) {
                font-size: 3.5rem;
            }
        }
    }

    &_zw_title{
        p{
           @include image_title_2;

           @media (max-width: 800px) {
                font-size: 3rem;;
            }

            @media (max-width: 530px) {
                font-size: 2.5rem;
            }

            @media (max-width: 450px) {
                font-size: 2rem;
            }
        
        }
    }
}