.tech_card{
    width: 18.2rem;
    height: 4.4rem;
    background-color: $color-primary4;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;

    @media (max-width: 1030px){
        width: 17rem;
        margin-top: 2rem;
    }
    @media (max-width: 600px){
        margin-left: 2rem;
    }
    @media (max-width: 537px){
        width: 20rem;
        margin: 1rem;
    }
    @media (max-width: 415px){
        width: 14rem;
    }
    @media (max-width: 340px){
        width: 14rem;
    }
    @media (max-width: 301px){
        width: 13rem;
    }


    &_polygon_left{
        @include polygon_side;
    }

    &_polygon_right{
        @include polygon_side;
        transform: rotate(180deg);
    }

    p{
        font-size: 2rem;

        @media (max-width: 1030px){
            font-size: 1.8rem;
        }
        @media (max-width: 415px){
            font-size: 1.5rem;
        }
    }
}