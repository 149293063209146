.contact{

    &_area{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 5rem 0;
        position: relative;

    }

    &_content{
        width: 68.5%;
        display: flex;
        // align-items: center;
        flex-direction: column;
       
        @media (max-width: 900px) {
            width: 85%;
        }

        @media (max-width: 700px) {
            width: 95%;
        }
    }

    &_background{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -999;
         img{
            height: 100%;
            width: 100%;
            opacity: .5;
            object-fit: cover;
         }
    }

    &_background_overlay{
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $color-black;
        opacity: .3;
        width: 100%;
        height: 100%;
    }

    &_info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:40%;

        @media (max-width: 1500px) {
            width: 45%;
        }

        @media (max-width: 1250px) {
            width: 55%;
        }

        @media (max-width: 1150px) {
            width: 60%;
        }

        @media (max-width: 790px) {
            width: 70%;
        }

        @media (max-width: 700px) {
            width: 100%;
        }
       
        p{
            font-size: 2rem;
            padding: 1rem 0;
            font-weight: 600;

            
            @media (max-width: 1545px) {
                font-size: 1.8rem;
            }
        }
    }

    &_phone, &_fax{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $color-gray-1;
        width: 49%;
        border-radius: 1rem 1rem 0 0;

        
    }

    &_icon{
        width: 2rem !important;
        height: 2rem !important;
        margin-right: .5rem;
    }

    &_map{
        width: 100%;
        height: 100%;

    }
}