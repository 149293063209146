.technology{
    width: 68%;
    height: 46.5rem;
    position: relative;

    @media (max-width: 900px){
        width: 85%;
    }

    &_title{
        @include component_title;
    }

    &_title_div{
        @include component_title_div;
    }

    &_title_text{
        @include component_title_text;
    }

    &_cards{
        width: 60rem;
        height: 22rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        position: absolute;
        right: 0;

        @media (max-width: 600px){
            justify-content: center;
            width: 100%;
            margin-top: 2rem;
            height: auto;
        }

    }

    &_background{
        margin-top: 5rem;
        padding: 1.2rem 0;

        @media (max-width: 1500px){
            margin-top: 6rem;
        }
        @media (max-width: 1380px){
            margin-top: 8rem;
        }
        @media (max-width: 1245px){
            margin-top: 12rem;
        }
        @media (max-width: 1030px){
            margin-top: 18rem;
        }
        @media (max-width: 600px){
            margin-top: 22rem;
        }
        @media (max-width: 537px){
            margin-top: 35rem;
        }
    }
}