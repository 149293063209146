.image_part{
    height: 54rem;
    position: relative;

    @media (max-width: 800px) {
        height: 40rem;
    }

    @media (max-width: 600px) {
        height: 35rem;
    }

    @media (max-width: 530px) {
        height: 30rem;
    }

    @media (max-width: 450px) {
        height: 25rem;
    }
    img{
        height: 100%;
        width: 100%;
        object-fit: cover; 
    }
}