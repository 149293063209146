.business_page{

    &_content{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;  

        @media (max-width: 755px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &_Bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -100;
        opacity: .5;
        img{
            width: 100%;
            height: 100%;
        }
    }

}