.ach_home{
    width: 35rem;
    height: auto;
    margin: 2rem 1rem;
    box-shadow: 8px 8px 5px 0px rgba($color-black, 0.25);
   
    @media (max-width: 607px){
        width: 45rem;
    }

    &_img{
        width: 100%;
        height: 25rem;
        display: flex;
        flex-direction: column;

        img{
            width: 100%;
            height: 25rem;
            object-fit: cover;
        }
    }

    &_title{
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7rem;
        color: $color-white;
        background-color: $color-black;
        padding: 5rem 1rem;
        text-align: center;
    }
}