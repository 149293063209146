.achievement_page{
    width: 100%;
    height: 100%;

    &_content{
        padding: 5rem 15rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        @media (max-width: 1380px){
            justify-content: space-around;
            padding-top: 2rem;
        }

        @media (max-width: 900px){
            padding: 2rem 5rem;
        }

        @media (max-width: 655px){
            padding: 1rem;
        }

    }

    &_Bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -100;
        opacity: .3;
        img{
            width: 100%;
            height: 100%;
        }
    }

    a{  
        height: auto;
        color: $color-black;
        text-decoration: none;
        transition: all .15s ease-in-out;
        margin: 2rem .5rem;

        @media (max-width: 607px){
            display: flex;
            justify-content: center;
        }

        &:hover{
           transform: translateY(-1.8rem);
        }
    }
}