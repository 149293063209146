.busniess_card{
    width: 50rem;
    height: auto;
    margin: 5rem 1rem;
    background-color: $color-gray-1;
    box-shadow: 5px 5px 5px 0px rgba($color-black ,.25);

    @media (max-width: 1520px) {
        width: 45rem;
    }

    @media (max-width: 1410px) {
        width: 40%;
    }

    @media (max-width: 900px) {
        width: 45%;
        margin: 3.5rem 1rem;
    }

    @media (max-width: 755px) {
        width: 90%;
        margin: 2rem 1rem;
    }


    &_img_overlay{
        position: absolute;
        width: 100%;
        height: 38rem;
        background: linear-gradient(360deg, $color-primary2 0%, rgba(16, 50, 95, 0.15) 35%);
        
        @media (max-width: 800px) {
            height: 35rem;
        }

        @media (max-width: 480px) {
            height: 30rem;
        }
    }

    &_img{
        position: relative;
        width: 100%;
        height: 38rem;

        @media (max-width: 800px) {
            height: 35rem;
        }

        @media (max-width: 480px) {
            height: 30rem;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_title{
        width: 100%;
        position: absolute;
        bottom: 0;
        color: $color-white;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 1.2;
        padding: 1.5rem 4rem;
    }

    &_list{
        width: 100%;
        height: auto;
        font-size: 2rem;
        padding: 2rem 6.3rem;
    }
}