.company{
    
    &_title{
        position: absolute;
        top: 22rem;
        left: 31rem;

        @media (max-width: 1205px){
            top: 24rem;
            left: 20rem;
        }
        @media (max-width: 900px){
            top: 22rem;
            left: 15rem;
        }
        @media (max-width: 800px){
            top: 17rem;
            left: 13rem;
        }
        @media (max-width: 607px){
            top: 17rem;
            left: 10rem;
        }
        @media (max-width: 600px){
            top: 15.5rem;
            left: 11rem;
        }
        @media (max-width: 530px){
            top: 14rem;
            left: 9rem;
        }
        @media (max-width: 480px){
            left: 7rem;
        }
        @media (max-width: 450px){
            top: 11rem;
            left: 7rem;
        }
        @media (max-width: 425px){
            top: 11rem;
            left: 5rem;
        }
        @media (max-width: 375px){
            top: 11rem;
            left: 3rem;
        }

        &_up{
            height: 7rem;
            display: flex;
            flex-direction: row;
            align-items: baseline;

            @media (max-width: 800px) {
                height: 5.5rem;
            }
            @media (max-width: 600px) {
                height: 4.5rem;
            }
            @media (max-width: 530px) {
                height: 4rem;
            }

            p{
                @include image_title_1;
                color: $color-primary2;

                @media (max-width: 800px) {
                    font-size: 4.5rem;
                }
                @media (max-width: 600px) {
                    font-size: 4rem;
                }
                @media (max-width: 530px) {
                    font-size: 3.5rem;
                }
            }

            span{
                @include image_title_2;
                color: $color-primary2;
                margin-left: 1rem;

                @media (max-width: 800px) {
                    font-size: 2.5rem;
                }
                @media (max-width: 600px) {
                    font-size: 2rem;
                }
                @media (max-width: 450px) {
                    font-size: 1.8rem;
                }
            }
        }

        &_down{
            height: auto;

            p{
                @include image_title_1;
                color: $color-primary4;
                width: 28rem;
                height: 7rem;
                margin-left: 10rem;

                @media (max-width: 800px) {
                    font-size: 4.5rem;
                    height: 5.5rem;
                    margin-left: 8rem;
                }
                @media (max-width: 600px) {
                    font-size: 4rem;
                    margin-left: 7rem;
                }
                @media (max-width: 530px) {
                    font-size: 3.5rem;
                }
            }
        }
    }

    &_content{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
    }

    &_content_Bg{
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -5;
        width: 96rem;
        height: 93rem;

        @media (max-width: 800px) {
            width: 80%;
            height: auto;
            bottom: -5%;
        }
        img{
            width: 100%;
            height: 100%;
            transform: scaleX(-1);
        }
    }
    
    &_content_title{
        width: 68%;
        font-size: 2.5rem;
        font-weight: 600;
        text-indent: 5rem;
        margin: 6rem 0;

        @media (max-width: 900px) {
            width: 80%;
            font-size: 2.5rem;
        }

        @media (max-width: 800px) {
            font-size: 2.2rem;
            margin: 4rem 0;
        }

        @media (max-width: 700px) {
            font-size: 2rem;
        }

        @media (max-width: 600px) {
            width: 90%;
            margin: 3rem 0;
        }
    }

    &_content_text{
        width: 68%;
        margin-bottom: 12.5rem;

        @media (max-width: 900px) {
            width: 80%;
        }

        @media (max-width: 800px) {
            width: 85%;
            margin-bottom: 4rem;
        }

        @media (max-width: 700px) {
            margin-bottom: 3rem;
        }

        @media (max-width: 600px) {
            width: 90%;
        }
    }

    &_content_part-1{
        @include company_contact;
        background-color: $color-gray-1;

    }
    &_content_part-2{
        @include company_contact;
        background-color: $color-primary2;
        color:$color-white
    }
    &_content_part-3{
        @include company_contact;
        background-color: $color-primary4;
    }

    
}