.achieve_display{
    width: 100%;
    height: auto;

    &_total{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }

    &_content{
        margin-top: 4rem;
        width: 68.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 900px){
            width: 90%;
        }
    }

    &_content_title{
        margin: 1rem 0;
        p{
            @include image_title_2;
        }
    }

    &_content_text{
        width: 90%;
        height: auto;
        text-align: center;
        p{
            font-size: 2rem;
        }
    }

    &_img_content{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 5rem 0;

      
    }

    &_img{
        width: 40rem;
        height: 100%;
        margin: 1.5rem 0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, 
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        transition: all .15s ease-in-out;

        &:hover{
            cursor: pointer;
            transform: translateY(-1.5rem);
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_lastpage{
        display: none;
        @media (max-width: 800px){
            display: block;
            margin-bottom: 1.5rem;
            a{
                text-decoration: none;
    
                p{
                    font-size: 1.4rem;
                    color: black;
                }
            }
        }
    }
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color-black, 0.5);
    z-index: 999;

    &-content{
      @include absolute-center;
        width: 80%;
        height: 90%;
      
        img{
            @include absolute-center;
            width: auto;
            height: 100%;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, 
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
    }
}

